import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Tasker - Add a Profile to deactivate Motion Detection",
  "path": "/Advanced_User/Tasker/Motion_Detection_Deactivate_Add_Profile/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Use a macro tool to automate functions of your camera",
  "image": "../AU_SearchThumb_Tasker.png",
  "social": "/images/Search/AU_SearchThumb_Tasker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Tasker_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Tasker - Add a Profile to deactivate Motion Detection' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a macro tool to automate functions of your camera' image='/images/Search/AU_SearchThumb_Tasker.png' twitter='/images/Search/AU_SearchThumb_Tasker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Tasker/' locationFR='/fr/Advanced_User/Tasker/' crumbLabel="Tasker" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "tasker---add-a-profile-to-deactivate-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tasker---add-a-profile-to-deactivate-motion-detection",
        "aria-label": "tasker   add a profile to deactivate motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tasker - Add a Profile to deactivate Motion Detection`}</h1>
    <h2 {...{
      "id": "how-do-you-disable-motion-detection-when-you-are-at-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-do-you-disable-motion-detection-when-you-are-at-home",
        "aria-label": "how do you disable motion detection when you are at home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do you disable motion detection when you are at home?`}</h2>
    <h4 {...{
      "id": "add-a-profile-motion-detection-disabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#add-a-profile-motion-detection-disabled",
        "aria-label": "add a profile motion detection disabled permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a profile (motion detection disabled)`}</h4>
    <p>{`Now we just have to automate the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Tasker/Motion_Detection_Deactivate_Add_Task/"
      }}>{`just created action`}</a>{`. Go to the profile tab and press the `}<strong parentName="p">{`plus`}</strong>{` to add a new profile. Here we choose the `}<strong parentName="p">{`state preset`}</strong>{` to use the WLAN connection status of the smart phone to trigger the action.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/86bbc0b148b916e8c81d1e7ffb54283a/772e8/Tasker-(17).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEHUlEQVQ4y42VWY7bRhCGea5cJDfJLfIYwA7gNyPJyPHYjhXbGY0Ma8amFi7aKA73TbREsrlTZJMUl2QoW9FQBpIP/0s3ulBVf3ejEEGUqDnG0jNRkkVREP8HPM8zDCOKIsIJMtn9cYn1ZVWXRFH6L2RZ5jhuOp0qioJwLEtTS44TBEHgeZ47gW9o7RyOMQzDcRyCESRGEOhohA7Hw/EEIwgMJwiCwHF83EAQX5YEQUwmk+FweNxEKBLH0Y8vLn69/O2Xt3+8WE2JKTbBcRzDsMvLy4uLi8nkfjmZTEiSvL6+fvr0aafTGY3HOI4jj9C7R+jdzyP28Yh5PLz76dPd76PFjMAJgvwCQR7yTBtms9lyPqNm0/mURAAsASytpLBh7cDKhnWYVnmWZ2mWZ0WaZmkK04bdbhc1GH6kuaEdxogPp3b8cZcxIZwFkAwSwktIkJBmjHopDQsT7s2q3hdFqaqqqiisKH/fpb57Nv/hVkTyIoR7Ny/CtLDh3kpyM86MdO/lRRTnZl5EVVXUdV2WZdjgB+FAtd+KgNj6SH2gqsviXlVVP6Q6Up+BQAhVVYEwWa0oTdM4jmMYhud5mqYZhjEMIwyDFq7nO54XhCGSZZlt20mScBy3Xq81TRMEQdf1w/NwHCeOY9d1vTNc1/1SdlmWRVHs93sAAMuyq9WKbaBpWpIk3/ePAbvdbrlcsiwbhiFyaOvYhm3b4/H46upqOp2iKIphGE3Tp8FRFFEUxfO87x8N+2oOAABCmOd5GIaHWw3D0D3BcRy/wbIspGXgdrsdDoez2cz5ymnDrusGQUCS5OFAO7NhGIvFgiCIb5rkeZ7v+6vViuM4AMCD4LIsTdOEEGZZ1qq2VbnjOIZhtMvWNA1F0dvbW5IkgyA4v54kSfr9Poqinue1y95sNrIs0zStKMohTys4CIL1er3ZbNqGlWVpGEaSJGmaxnF8/5KbgBagYbvdtjPr6zVOEJ9QdHBzM7i5kVU1jCK3MeooiqIkSTLOgzebjSoIMsvei+MsXfctyzPNo1zD2KqqD8DmgWFVVda19fx53OnAXg++exe/eRN1u+Hr16eKul371SvnyRPQ7z8IruraePbstt//czBYclyQZW6SeBAe5SZJlOd/jcfUhw9er3eW+eVL0Olol5dWtxteX39DvZ7T6wWdjvX+/VnPmgZ4fqfroab5inKuQFVNlgWCYLYMq6tqs93i8/kARe94PsnzMEkiCP9VksRZdicIvCwD20bKsqyqqmjY7/eqqgqCcBgIiqLIsnw6pQ4Th2VZSZI0TUN0XVdV1bIs0zQty5IkablcLhomDfMzFovFYDC4Hzeqqo5GI03TAACGYQAAjp+WafB9v/XCPM8jSfLz58+IZVnr9VrX9UNy44Rtg/EtdF3/51f9DXe2tEhehHVfAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/86bbc0b148b916e8c81d1e7ffb54283a/f13a9/Tasker-(17).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/86bbc0b148b916e8c81d1e7ffb54283a/ba381/Tasker-(17).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/86bbc0b148b916e8c81d1e7ffb54283a/772e8/Tasker-(17).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/86bbc0b148b916e8c81d1e7ffb54283a/772e8/Tasker-(17).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Go to the Profile tab and click the + to add a state profile.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e3a20311ded5d8d13df06864ed1f7fb6/772e8/Tasker-(19).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "160%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD+ElEQVQ4y9WUW08bRxTH93P1Axg1imTiNZjCS1ysPrj5GFGkfIhI5QEVMEVWizFVWgWR+IIxNdXWYHxZ2+zd9uzu3HbXNuxU3o0JCYqE1Kf+NBrNnDl/nXNmzi7H83ws9iwWe8bzfDyAD4g/YHFxMRaL3fnE43GOjy/Fn3zDLz5ZWPg2Eok8ffp0IeC7B0QikWg0ura2lkgkeJ5fXV3lEolEPBZdXl5aWlqKx+N3c+IB0WiU5/mVlZXlwDuRSHDJZPJ5Mvn8ESQD7m+5dDr94h4/zseLe4u7o/uk02kum83alqkr8kBVdFXRVMUIhhZsjWDW50chkiRZlrW5ucn9+fYtvmVtQHq2IyNXRa5oOz3bMbArQ1e0ZxYNuxpyAfVCKKWMsb29Pe6P3w919/ZYskoqPDdQF+ATDRZVWB+i1ggXFFjR4JFs1wx0OURjh1JKMUKMsd3dXe63g/z41rcQgoTamNiYQBLMlNqEopmRgsBoETobmI4gmjK2tZPhtn/Nae5Ne2i3BpY4gl2AOiMoAtQaWE3DbBpWZ4S6AM0shtUyzJZhXulA925/+nmHyx/kGGMYwuL7951W83Y6IQjeTMZiu1UqfCgVPsj9/nTsuZS0m1enJ+Wz00qlXLqZjH/ZzXC5g7zPGPG8Rrs9ME1nOkWUYkIIpbKiKKoarhHGlNLBYKio2ggAxtj29jaXy2YZAESShs0mkSRHlomuY0I8z7Msy7Zt13Uxxgghx3EwxgAAQshH8cGrV+zsDFUqrWx2dHw8rlZpPu8gpOl6o9Ho9XoAgNkNYwwhbDQa7XZbUZS5+PVrNp1C3//r4kKD0GOMVKuuaQ4B+EcQ6vX6aDQKY1JKBUE4OjqSJIkxtrW1xR28fMmurx3DkAUB9fuuqjrv3hEAJjc3iqIMBoPxeEwIwRhPJhNVVYvF4mQymdf85g2r1WChQMtlt1KhxSI5Owu9w1LDnO+wLAtj/FG8f3jIGLvqdk/Oz/9uNPBkgoIeFEWxVCrVajVd1x1n1lmGYZTL5dPTU0EQ5uL9fcaYbVnNq6uBrjtB94XRrgOCl6JhzZIkXVxcgE9Plcv5vu84TujnOA5CKKzQtu1wESbsui4AQBRF13U/iyzLcr/fV1UVzZEkSZZlURQ1TcN41ilhCp7nQQg/E0uSVCgUzoKrCrm8vKxWq6VSSRCEO2UICr6qnZ2dWdqMMdd16/W6ZVme54VxfN9vt9uapvm+/1VxGBlCqOs6QojcQ9d10zS/UH4Z2fd9CGH4HvdxAugDPonz+TwhpNlsyrLsPY7wN5TJZLhMJjMcDjudTrfbvX4cvV7PNM2NjQ0ulUp9Pyf5aNbX11OpFPfDf+B/Kv4XqXaCXWbs3x8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e3a20311ded5d8d13df06864ed1f7fb6/f13a9/Tasker-(19).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e3a20311ded5d8d13df06864ed1f7fb6/ba381/Tasker-(19).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e3a20311ded5d8d13df06864ed1f7fb6/772e8/Tasker-(19).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e3a20311ded5d8d13df06864ed1f7fb6/772e8/Tasker-(19).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Then select the Net Tile.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c24d39300e87b2702df42e04581ea42/772e8/Tasker-(22).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "158.50000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAACWklEQVQ4y+2Ta2+bMBSG/f/3YzpRTdBElZpeMlLlskWsocFAUKK1jTAO+MIdJrBiuU2kfZj2rc83BIfznPPawHHg068fT9bi9vbu+3hsmuZdj9fj9kAIfd+fz+er1crzPAjher12XRcsflrzyy/zsfFNH1xqmmEYmqYNBgPLshzHcY9ACDVNu7m5CYLAtu3pdOr7PnCc9cparuznycR8eHh4fHw0TXMymdzf39u2LZu7rjubzSzL8jzPcZzn9RpCCJIkiROCEIqiCCEUhiHqiaIo7JGPh8Mh7iGE5CljjIHtdjsajRBCGOPoPYeeOI6TJKE9nPOiKCjjQ+c3yUvw+vq6XC6jKDotxhjLekIIpZQxnmcZZdzf47quQVVVTdNwztk5+JE0TbMsy/O8KIq6rtsegDGGEApJ/B5VW3TmnGc9Hop5UQHXdQ3DCMNQLExFmIu/iHrGWJ7nh4ToT0FaliBNU0opIUS8ThTIEdFTmpdl2bZNp51lmfxOdOjC61H/0m+LpWnaFRdFVpRlXXfauq7vdrvr6+uLi4urq6v9fi9GUHeuaseEfLU2XVQY4yAIEEK73W6z2Wy3W3VUqaB2TrMMU9a2TRdVXddqPOqoApGZnLmqqrZtm6YBLy8v0+lUHk9p+6H/sXkXFefcDN467SAIRqPR2ahk4MoJY3meJZTNgreyqjrtoijIOaTzWe0uKkqpMEQIHd6jBqYuTOysmxlCOBwOdV1fLBZxHMs7eHrCjto5pTQMw6qqAGPswx1SO8tDIs3F3RDmoD3SnND+DdD+A5/Fn8X/t/gPOwYc5844JnYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c24d39300e87b2702df42e04581ea42/f13a9/Tasker-(22).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c24d39300e87b2702df42e04581ea42/ba381/Tasker-(22).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c24d39300e87b2702df42e04581ea42/772e8/Tasker-(22).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c24d39300e87b2702df42e04581ea42/772e8/Tasker-(22).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`We would like to carry out the action as soon as the smart phone connects to the mobile network.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1dfda7b6ec54e9d123b983ff257f4320/772e8/Tasker-(23).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD8UlEQVQ4y6WU3Y6bRhTHea4+SJ+gj1D1qte96H2rpFKVXGzkrpNV0zTpVlpv0931Fx9jbK9tMN8YMAyDx2CwAWNwtZBWjndXidSf/hpmzszhjDjnQEiyMhpQU24gK6osS/JnIIoix3GKohBTUe69+f6WuVI1Q5Fl5VOoqioIQq/X0zSNEKbT8WgkCKIkSaIoCgeIJUeW6hjP84IgEDTo0QC0u912l+yQFA0AzQAAAMMwZAnDMNUSAEBRVLfbJUkSlBBjFlDN619Pa6/rv/z55vWYBSxNMQxDUdTZ2Vm9XqeoD0uWZS8uLk5OTur1lyR5ZySetLknbe6nLv+0yz/tcD+0JmfksN8DAPT+o4rDlvT7/dtBf9RnB2yPQHHuxQXaZF68r7RKim26TZN0m+6SJE2SOCmJoigscfxwhlfeak34MfDW1+uUX8X9IGb9mFnGPW/DwnXbT7g4g3HmFvvdbpfruq5rGi+rX74dffFy+M2NTCTZapN6SeavU3edOlFqR6mdZH6arTZbN82CPM+KosjzfFWyDIL3GvpDcml7Sew/j6Io7hsJwzAkSVQU2TRNWZbmc8swDasEeciyTMexEUKbzca2bXs+10zr25by1aX448AiNE0bDofj8VjX9dvbW57nJ5OJpmmKoliWJcvybDbTdT2KIgghdJzZ3P6uI399Lf48ND/32g9CpGmKMV6v14qiaJpmmmY1mqZ594n/pTggy++0KwoiDENVVaMomkwmgiBUpctxnG3bn45cPbIsi0vCMKzGJEnyPE/TdPsIu92OqHJY3We/3+u63ul0eJ4HAEyn0yAIMMbLe1RG4jCTRVGU6ZhjjE3TxBiHYfiY52KxII4qwXGcRqPRarU4jru6umo2m4eRMcZBEPA8r2nasXOe5xDCKskQQkmSVFU9Cuv7PoTQ8zzXdY+dbdv2Ma4UrVZhECwQwp53qOVigT3Pse3ja5uW9dv5OcmyVL//vtlsXF2NRXGVJDiKlut1pWruQPhx5KKwFQUCgPp9l2UdABwAFsNhMJn44/GhgsnEns2Iw7j5fg/Pz/Hl5YJhMEWtej1MUUuaXtK0R5JLmvYZxqfp5WCwfPcOttvEUdc55+e/n57S4/FNt/tXs/l3u00PBpc3N43r60UYRtttGMer/V7rdFCrdexsNhrq8+dyvS7XasKLF1KtJtVqYjlRT08/6NUr7dkzg6KOuypKEnexgAhBhFzPg6XccnkoF+Mojv9fS1ZVuXuIquwf27prDMMwdF13XRd+jOu6s9nMMIz7WxBCjuPm8zmhqipJkkeHHMdBCA2Hw9FohBC6/97KhUAIGcbdH+9+BKcEPoRlWRDCfwBYQcIPQn7+1wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1dfda7b6ec54e9d123b983ff257f4320/f13a9/Tasker-(23).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1dfda7b6ec54e9d123b983ff257f4320/ba381/Tasker-(23).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1dfda7b6ec54e9d123b983ff257f4320/772e8/Tasker-(23).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1dfda7b6ec54e9d123b983ff257f4320/772e8/Tasker-(23).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Connect the profile to a previously created task.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c24d39300e87b2702df42e04581ea42/772e8/Tasker-(22).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "158.50000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAACWklEQVQ4y+2Ta2+bMBSG/f/3YzpRTdBElZpeMlLlskWsocFAUKK1jTAO+MIdJrBiuU2kfZj2rc83BIfznPPawHHg068fT9bi9vbu+3hsmuZdj9fj9kAIfd+fz+er1crzPAjher12XRcsflrzyy/zsfFNH1xqmmEYmqYNBgPLshzHcY9ACDVNu7m5CYLAtu3pdOr7PnCc9cparuznycR8eHh4fHw0TXMymdzf39u2LZu7rjubzSzL8jzPcZzn9RpCCJIkiROCEIqiCCEUhiHqiaIo7JGPh8Mh7iGE5CljjIHtdjsajRBCGOPoPYeeOI6TJKE9nPOiKCjjQ+c3yUvw+vq6XC6jKDotxhjLekIIpZQxnmcZZdzf47quQVVVTdNwztk5+JE0TbMsy/O8KIq6rtsegDGGEApJ/B5VW3TmnGc9Hop5UQHXdQ3DCMNQLExFmIu/iHrGWJ7nh4ToT0FaliBNU0opIUS8ThTIEdFTmpdl2bZNp51lmfxOdOjC61H/0m+LpWnaFRdFVpRlXXfauq7vdrvr6+uLi4urq6v9fi9GUHeuaseEfLU2XVQY4yAIEEK73W6z2Wy3W3VUqaB2TrMMU9a2TRdVXddqPOqoApGZnLmqqrZtm6YBLy8v0+lUHk9p+6H/sXkXFefcDN467SAIRqPR2ahk4MoJY3meJZTNgreyqjrtoijIOaTzWe0uKkqpMEQIHd6jBqYuTOysmxlCOBwOdV1fLBZxHMs7eHrCjto5pTQMw6qqAGPswx1SO8tDIs3F3RDmoD3SnND+DdD+A5/Fn8X/t/gPOwYc5844JnYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c24d39300e87b2702df42e04581ea42/f13a9/Tasker-(22).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c24d39300e87b2702df42e04581ea42/ba381/Tasker-(22).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c24d39300e87b2702df42e04581ea42/772e8/Tasker-(22).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c24d39300e87b2702df42e04581ea42/772e8/Tasker-(22).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Now both profiles are active.`}</li>
    </ol>
    <p>{`To make sure that the action is executed only when we are in our home Wi-Fi, we need to set a value that Tasker can use to detect it. In our case we will simply choose the name of the network (SSID). In our case, the profile will only perform the action if the smart phone connects to a Wi-Fi network called WTec. You can also assign a fixed IP address to your phone via the router, or use the MAC address of your wireless router as an indicator. Then go back to save the profile and add the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Tasker/Motion_Detection_Add_Task/"
      }}>{`previously created task`}</a>{` to the profile.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6a8b8a817f1edc18479a8d6fbec9865d/772e8/Tasker-(11).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAACnUlEQVQ4y+WUbXeTMBiG+f/+GHfsqc6V6Wpdd1a1UrSUAcLpVtuN94SEQBKoB9JlfZlu+tXrPB84IXcS7psnimU7U/3TVP/S738YDAbn5+eqqg4GA9d1f9zjOI7neZeXl4ZheJ5n2/ZsNnNdV9Em3752XoyHve6bt53OK1VVj4+Pe72eruuO4wix67q2bXc6nX6/P5/PTdMcj8ee5ymOY5szwzSvRqPRZDL53DIajS4uLqRYoGmaYRjiROKVghsQQggAEMdxskXUIgcRQtk9GOOyLJXr63m32z09PV0ul2maitmCtAUAACHMsgwhlOc5YyyOY8uyOOeK7/u6rk+n0yAIol22l4AQIoQwxkVRUErv7u4QQkpd1+uWuq7pHoxRdg9vqRqkRFmv12mSLH8uIEjl6NM0M+tGPP5uvBt8fN1TV2FU1mtMec4qWUQWfyhEOatrhfI6KquE1QmvA8KeU2HBgoKXVa1QSkme5whlAGQQouyhcJY1hVCOEclxQXJaEM4oLcuyIM03W7b98ujo/dnZ6vY2juN9u5MkEYFt0sJlSaMoNs0rSmkTlWVZruvKqGTOQghaZM6EEEppEAQYY2XLv4aqqtgufAuR005UhJA0TVELxnj9bBoxAMDzvNVqtVgsgiCQp/gD4gjK4Sh7CvEJjZgQEoYhACA9QFgluwJjTAgpy1LoG7FpmsPhEEIYHbCV1KYx8jwXjcEYa8QAADEjSZK9fpb9KFtK7Ewp5ZxvDNvrhyfd2nEbY+z7vtgNQvh3UUEIb25ufN+/bf/Q6hlsDJNPIifOOX2Mx6NCCIVhGEWROPm2Z4/eYWVLURSN2HEcVVVPTk40TUvTVCz0u6jEpSn2q6qquXpFJ8p5hz+JkG1HxRjbGPbP/I/iX2bsH3VOCxxrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a8b8a817f1edc18479a8d6fbec9865d/f13a9/Tasker-(11).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6a8b8a817f1edc18479a8d6fbec9865d/ba381/Tasker-(11).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a8b8a817f1edc18479a8d6fbec9865d/772e8/Tasker-(11).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6a8b8a817f1edc18479a8d6fbec9865d/772e8/Tasker-(11).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Define here where Tasker should recognize your home network and assign the previously created task to the profile.`}</li>
    </ol>
    <p>{`Your Android smartphone will now automatically activate the motion detection of your INSTAR HD IP camera as soon as `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Tasker/Motion_Detection_Add_Profile/"
      }}>{`arrive back home`}</a>{`. If you leave your home and the smartphone loses the connection to the home WLAN, motion detection is automatically deactivated again. If you want to do the opposite and only activate motion detection when you are not at home, you only need to swap the two CGI commands in `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Tasker/Motion_Detection_Add_Task/"
      }}>{`Task I`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Tasker/Motion_Detection_Deactivate_Add_Task/"
      }}>{`Task II`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      